<div class="slider-container">
    <div class="slider">
        <div class="slide-container" style="--selected-slide: {selectedSlide}">
            {#each slides as slide, index}
                <div
                    class="slide"
                    style="--color: {slide.color}"
                    class:selected={index === selectedSlide}
                    on:click={() => onSlideClick(index)}
                    on:touchstart={(event) => startSwipeGesture(index, event, true)}
                    on:mousedown={(event) => startSwipeGesture(index, event, false)}
                >
                    <div />
                    <div class="about">
                        <div class="title">{@html slide.title}</div>
                        <div class="description">{@html slide.description}</div>
                        {#if index === selectedSlide}
                            <a href={slide.link} target="_blank" rel="noopener noreferrer">Sprawdź</a>
                        {:else}
                            <div class="link">Sprawdź</div>
                        {/if}
                    </div>
                    <img src={slide.image} alt={slide.title} />
                </div>
            {/each}
        </div>
    </div>
    <div class="control">
        <button class="arrow-container" on:click={() => (selectedSlide -= 1)} disabled={selectedSlide === 0}>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="30" height="30" rx="15" class="circle" />
                <path d="M23 15L7.00002 15" class="arrow" />
                <path d="M12 20L7.00002 15L12 10" class="arrow" />
            </svg>
        </button>
        {#each slides as slide, index}
            <button class="dot-container" disabled={index === selectedSlide} on:click={() => (selectedSlide = index)}>
                <div class="dot" />
            </button>
        {/each}
        <button
            class="arrow-container right"
            on:click={() => (selectedSlide += 1)}
            disabled={selectedSlide === slides.length - 1}
        >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="30" height="30" rx="15" class="circle" />
                <path d="M23 15L7.00002 15" class="arrow" />
                <path d="M12 20L7.00002 15L12 10" class="arrow" />
            </svg>
        </button>
    </div>
</div>

<svelte:options tag="svelte-slider" />

<script lang="ts">
    import type { Slide } from './slider.type';

    let slides = parseSlideData($$props['slides']);

    let selectedSlide = 0;

    function parseSlideData(data: string | Slide[] | undefined) {
        if (data === undefined) {
            throw Error('Slides must be defined!');
        }

        if (typeof data !== 'string') {
            return data as Slide[];
        }

        return JSON.parse(data) as Slide[];
    }

    function onSlideClick(index: number) {
        if (index !== selectedSlide) {
            selectedSlide = index;
        }
    }

    function getClientX(event: MouseEvent | TouchEvent, isMobile: boolean) {
        return (isMobile ? (event as TouchEvent).touches[0] : (event as MouseEvent)).clientX;
    }

    function startSwipeGesture(index: number, event: MouseEvent | TouchEvent, isMobile: boolean) {
        const MINIMAL_GESTURE_RANGE = 50;

        const LEFT = -1;
        const STATIONARY = 0;
        const RIGHT = 1;

        isMobile || event.preventDefault();

        if (index !== selectedSlide) {
            return;
        }

        const startX = getClientX(event, isMobile);

        let direction = STATIONARY;

        const moveEvent = isMobile ? 'touchmove' : 'mousemove';
        const endEvent = isMobile ? 'touchend' : 'mouseup';

        function moveSwipeGesture(event: MouseEvent | TouchEvent) {
            const currentX = getClientX(event, isMobile);

            const xDifference = currentX - startX;

            if (xDifference >= MINIMAL_GESTURE_RANGE) {
                direction = LEFT;
            } else if (xDifference <= -MINIMAL_GESTURE_RANGE) {
                direction = RIGHT;
            } else {
                direction = STATIONARY;
            }
        }

        function endSwipeGesture() {
            document.removeEventListener(moveEvent, moveSwipeGesture);
            document.removeEventListener(endEvent, endSwipeGesture);
            document.removeEventListener('blur', endSwipeGesture);

            const finalSlider = selectedSlide + direction;

            if (finalSlider >= 0 && finalSlider <= slides.length - 1 && direction) {
                setTimeout(() => (selectedSlide = selectedSlide + direction));
            }
        }

        document.addEventListener(moveEvent, moveSwipeGesture);
        document.addEventListener(endEvent, endSwipeGesture);
        document.addEventListener('blur', endSwipeGesture);
    }
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

    * {
        box-sizing: border-box;
    }

    .slider-container {
        position: relative;

        .slider {
            overflow: hidden;
            display: flex;
            justify-content: center;

            .slide-container {
                display: flex;
                width: 1120px;
                height: 560px;
                transform: translateX(calc(-1 * var(--selected-slide) * 100%));
                transition: 0.6s cubic-bezier(0, 0.57, 0.29, 1.28);

                @media (max-width: 1439px) {
                    width: 100%;
                    aspect-ratio: 11 / 6;
                    height: auto;
                }

                @media (max-width: 619px) {
                    height: calc(100vw + 160px);
                }

                .slide {
                    background-color: var(--color);
                    width: 1100px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    position: relative;
                    flex-shrink: 0;
                    margin: 0 10px;
                    transition: 0.3s;

                    @media (max-width: 1439px) {
                        margin: 0;
                        width: 100%;
                    }

                    @media (max-width: 619px) {
                        align-items: flex-start;
                    }

                    &:not(.selected) {
                        opacity: 0.4;
                        cursor: pointer;

                        @media (max-width: 1439px) {
                            opacity: 1;
                        }
                    }

                    .about {
                        margin-left: 58px;
                        z-index: 1;

                        @media (max-width: 1439px) {
                            margin-left: 2.778vw;
                        }

                        @media (max-width: 619px) {
                            margin-left: 0;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            width: 100%;
                            margin-top: 32px;
                        }

                        .title {
                            font-weight: 700;
                            font-family: 'Poppins';
                            color: #ffffff;
                            font-size: 60px;
                            line-height: 72px;

                            @media (max-width: 1439px) {
                                font-size: 4.167vw;
                                line-height: 5vw;
                            }

                            @media (max-width: 619px) {
                                text-align: center;
                                font-size: 32px;
                                line-height: 40px;
                            }
                        }

                        .description {
                            font-weight: 400;
                            font-family: 'Poppins';
                            color: #ffffff;
                            font-size: 18px;
                            line-height: 24px;
                            margin-top: 16px;

                            @media (max-width: 1439px) {
                                font-size: 1.25vw;
                                line-height: 1.667vw;
                                margin-top: 9px;
                            }

                            @media (max-width: 619px) {
                                display: none;
                            }
                        }

                        a {
                            margin-top: 24px;
                            background: #ffffff;
                            border-radius: 24px;
                            font-family: 'Poppins';
                            font-weight: 600;
                            font-size: 16px;
                            text-transform: uppercase;
                            height: 48px;
                            padding: 0 74px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: max-content;
                            text-decoration: none;
                            color: #012237;

                            &:hover {
                                color: var(--color);
                                outline: 3px solid rgba(255, 255, 255, 0.6);
                            }

                            @media (max-width: 1439px) {
                                margin-top: 16px;
                            }
                        }

                        .link {
                            margin-top: 24px;
                            background: #ffffff;
                            border-radius: 24px;
                            font-family: 'Poppins';
                            font-weight: 600;
                            font-size: 16px;
                            text-transform: uppercase;
                            height: 48px;
                            padding: 0 74px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: max-content;
                            text-decoration: none;
                            color: #012237;

                            @media (max-width: 1439px) {
                                margin-top: 16px;
                            }
                        }
                    }

                    img {
                        position: absolute;
                        height: 100%;
                        right: 0;

                        @media (max-width: 619px) {
                            height: auto;
                            width: 100%;
                            bottom: 0;
                        }
                    }
                }
            }
        }

        .control {
            display: grid;
            padding: 5px 8px;
            height: 40px;
            border-radius: 20px;
            grid-auto-flow: column;
            grid-auto-columns: max-content;
            background-color: #ffffff;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);

            button {
                width: 30px;
                height: 30px;
                border: 0;
                background: none;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                cursor: pointer;

                &.arrow-container {
                    &.right {
                        transform: rotate(180deg);
                    }

                    &:hover {
                        .circle {
                            fill: #f2f2f2;
                        }
                    }

                    &:disabled {
                        .circle {
                            fill: #ffffff;
                        }

                        .arrow {
                            stroke: rgba(1, 34, 55, 0.2);
                        }
                    }

                    svg {
                        .circle {
                            fill: #ffffff;
                            transition: 0.2s ease-out;
                        }

                        .arrow {
                            stroke: #012237;
                            stroke-width: 2;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            transition: 0.2s ease-out;
                        }
                    }
                }

                &.dot-container {
                    .dot {
                        width: 16px;
                        height: 16px;
                        background: rgba(1, 34, 55, 0.2);
                        transition: 0.2s ease-out;
                        border-radius: 100%;
                    }

                    &:disabled {
                        .dot {
                            background: #012237;
                        }
                    }

                    &:not(:disabled):hover {
                        .dot {
                            background: #e74d57;
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                &:disabled {
                    cursor: default;
                }
            }
        }
    }
</style>
