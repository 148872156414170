<hr />
<div class="gallery">
    <div class="title">ZDJĘCIA Z ZAJĘĆ</div>
    <div class="tiles">
        {#each tiles as tile, index}
            <div class="tile" style="background-image: url({tile})" on:click={() => (expandedIndex = index)}>
                <div class="wrapper">
                    <svg
                        version="1.1"
                        id="Warstwa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 30.6 30"
                        style="enable-background:new 0 0 30.6 30;"
                        xml:space="preserve"
                    >
                        <style type="text/css">
                            .st0 {
                                fill: #e84c57;
                            }
                            .st1 {
                                fill: #ffffff;
                            }
                        </style>
                        <path
                            class="st0"
                            d="M15.3,0C6.9,0,0,6.7,0,15c0,8.3,6.9,15,15.3,15s15.3-6.7,15.3-15C30.6,6.7,23.7,0,15.3,0L15.3,0z"
                        />
                        <path
                            class="st1"
                            d="M17.1,7.3c0,0,0.1,0,0.1,0l4.4,0c0.7,0,1.3,0.6,1.3,1.2l0.1,4.5c0,0.3-0.1,0.7-0.4,0.9
	c-0.2,0.2-0.6,0.4-0.9,0.4c-0.3,0-0.7-0.1-0.9-0.4c-0.2-0.2-0.4-0.6-0.4-0.9l0-1.5L18,14.1c-0.2,0.3-0.6,0.4-0.9,0.4
	c-0.4,0-0.7-0.1-1-0.4c-0.3-0.2-0.4-0.6-0.4-0.9c0-0.4,0.2-0.7,0.4-0.9l2.4-2.4l-1.3,0c-0.7,0-1.3-0.5-1.3-1.2
	C15.9,8,16.4,7.4,17.1,7.3L17.1,7.3z"
                        />
                        <path
                            class="st1"
                            d="M13.4,15.5c0.5,0,1,0.3,1.2,0.8c0.2,0.5,0.1,1-0.3,1.4L12,20.1l1.3,0c0.3,0,0.7,0.1,0.9,0.4
	c0.2,0.2,0.4,0.6,0.4,0.9c0,0.3-0.1,0.7-0.4,0.9c-0.2,0.2-0.6,0.4-0.9,0.4l-4.4,0c-0.7,0-1.3-0.6-1.3-1.2l-0.1-4.5
	c0-0.7,0.5-1.3,1.2-1.3c0.4,0,0.7,0.1,0.9,0.3c0.3,0.2,0.4,0.6,0.4,0.9l0,1.5l2.4-2.4C12.8,15.7,13.1,15.5,13.4,15.5L13.4,15.5z"
                        />
                        <path class="st0" d="M13.4,15.5" />
                    </svg>
                    <div class="label">powiększ</div>
                </div>
            </div>
        {/each}
    </div>
</div>
{#if expandedIndex !== null}
    <div class="lightbox" transition:fade={{ duration: 100 }} on:click|self={() => (expandedIndex = null)}>
        <div
            class="next"
            on:click={() => (expandedIndex < tiles.length - 1 ? expandedIndex++ : null)}
            class:gray={expandedIndex >= tiles.length - 1}
        >
            <svg width="49" height="81" viewBox="0 0 49 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32.0147 40.5L0.757385 9.24264L9.24267 0.757355L48.9853 40.5L9.24267 80.2426L0.757385 71.7574L32.0147 40.5Z"
                    fill="white"
                />
            </svg>
        </div>
        <div
            class="prev"
            on:click={() => (expandedIndex !== 0 ? expandedIndex-- : null)}
            class:gray={expandedIndex < 1}
        >
            <svg width="49" height="81" viewBox="0 0 49 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M32.0147 40.5L0.757385 9.24264L9.24267 0.757355L48.9853 40.5L9.24267 80.2426L0.757385 71.7574L32.0147 40.5Z"
                    fill="white"
                />
            </svg>
        </div>
        <div class="close" on:click={() => (expandedIndex = null)}>
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.08033 0L0 7.08033L29.4197 36.5L7.26328e-05 65.9196L7.0804 72.9999L36.5 43.5803L65.9196 72.9999L72.9999 65.9196L43.5803 36.5L73 7.08036L65.9197 2.76816e-05L36.5 29.4197L7.08033 0Z"
                    fill="white"
                />
            </svg>
        </div>
        <div class="image">
            <img src={tiles[expandedIndex]} alt="" />
        </div>
    </div>
{/if}

<svelte:options tag="svelte-gallery" />

<script lang="ts">
    import { fade } from 'svelte/transition';

    let tiles: string[] = JSON.parse($$props['tiles']);

    let expandedIndex: number | null = null;
</script>

<style lang="scss">
    hr {
        border-style: dashed;
        border-color: #f1f1f1;
        margin: 64px 0;

        @media (max-width: 992px) {
            margin: 32px 0;
        }
    }

    .lightbox {
        position: fixed;
        width: 100vw;
        height: calc(100vh - 106px);
        background: #000000d2;
        top: 106px;
        left: 0;
        z-index: 99999;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 60px;
        box-sizing: border-box;
        user-select: none;

        @media (max-width: 992px) {
            height: calc(100vh - 65px);
            top: 65px;
        }

        .close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 16px;
            cursor: pointer;

            svg {
                height: 24px;
            }
        }

        .next,
        .prev {
            width: 30vw;
            position: absolute;
            bottom: 0;
            height: 90vh;
            display: flex;
            align-items: flex-end;
            padding: 0 24px;
            cursor: pointer;

            @media (max-width: 1260px) {
                padding: 0 8px;
            }

            &.gray {
                opacity: 0.3;
            }

            svg {
                height: 48px;
                margin-bottom: 60px;

                @media (max-width: 1260px) {
                    height: 32px;
                }
            }
        }

        .next {
            right: 0;
            justify-content: flex-end;
        }

        .prev {
            left: 0;

            svg {
                transform: rotate(180deg);
            }
        }

        .image {
            height: 100%;
            display: flex;
            max-width: 100%;
            max-height: 100%;

            img {
                display: flex;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                aspect-ratio: 16/9;
                background-color: #fff;
            }
        }
    }

    .gallery {
        width: 100%;

        .title {
            font-weight: bold;
            margin-bottom: 32px;
        }

        .tiles {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;

            @media (max-width: 1200px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 600px) {
                grid-template-columns: 1fr;
            }

            .tile {
                aspect-ratio: 16/9;
                background-size: cover;
                background-position: center;
                cursor: pointer;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    .wrapper {
                        opacity: 1;
                    }

                    &::before {
                        opacity: 0.6;
                    }
                }

                .wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    opacity: 0;
                    transition: 0.3s;
                    position: relative;

                    svg {
                        height: 48px;
                    }

                    .label {
                        margin-top: 16px;
                        color: #fff;
                    }
                }

                &::before {
                    content: '';
                    opacity: 0;
                    display: flex;
                    width: 100%;
                    position: absolute;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: #e84c57;
                    transition: 0.3s;
                }
            }
        }
    }
</style>
